import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "poposki_first_head_coach_match" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>Saško Poposki yesterday had his debut game in Latvian Virsliga as an acting Head Coach of Riga Football Club and won against BFC Daugavpils (7-1). </p>
          <p>Catenaccio Sports Agency congratulates Mr. Poposki and wishes him all best of luck in future games.</p>
        </div>
      </div>
    )
  }
}